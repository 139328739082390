























































































import { Component, Vue, Watch } from "vue-property-decorator";

import EntityCrud from "../../entity/EntityCrud.vue";
import SolutionAppointmentAppModel from "@/api/solution_appointment_app.model";
import clientAppModel from "@/api/client_app.model";
import differenceInDays from "date-fns/differenceInDays/index";
import { readableDay } from "@/helpers/calendar";
import UserCard from "@/layouts/components/UserCard.vue";
import Calendar from "@/layouts/components/Calendar.vue";
import { BOverlay } from "bootstrap-vue";
import { AppointmentStatus } from "@/interfaces/appointment";
import { startOfDay } from "date-fns";
import { formatDayMonthYear } from "@/helpers/utils";

const today = new Date();

@Component({
	components: {
		UserCard,
		EntityCrud,
		Calendar,
		BOverlay,
	},
})
export default class ClientSearch extends Vue {
	searchLabel = "Digite um nome, CPF, Data de nascimento, Carteirinha ou Telefone";
	model: any = clientAppModel;
	kind = "cardsTable";
	isCalendarOpen = false;
	selectedDate = "";
	today = startOfDay(new Date()).toISOString();

	currentPage = 1;
	pageLimit = 15;

	appointments: any = [];
	areAppointmentsLoading = false;

	activeUser = this.$store.state.auth.activeUser;

	totalAppointments = 0;
	priorityAppointmentKey = 0;
	regularAppointmentKey = 0;

	priorityAppointmentsList = [];
	regularAppointmentsList = [];

	tableColumns = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			name: "Carteirinha",
			key: "insurerNumber",
			kind: "nameWithData",
		},
		{ name: "Data de Nascimento", key: "birthDate", kind: "date", onTransform: formatDayMonthYear },
		{ name: "CPF", key: "cpf", kind: "document" },
		{ name: "Status", key: "isActive", kind: "status" },
	];

	appointmentsHeaders = [
		{ key: "name", kind: "userName" },
		{ key: "lastName", kind: "userName" },
		{
			content: [
				{ key: "birthDate", kind: "age" },
				{
					key: "gender",
					kind: "status",
				},
				{ key: "corporateClient", relation: "name" },
			],
		},
	];

	@Watch("selectedDate")
	closeCalendar() {
		this.isCalendarOpen = !this.isCalendarOpen;
	}

	get readableDate() {
		return this.selectedDate ? readableDay(this.selectedDate) : readableDay(this.today);
	}

	generatePriorityAppointmentsList() {
		this.priorityAppointmentsList = this.appointments.filter(
			(data: any) =>
				differenceInDays(new Date(data.returnDate), this.selectedDate ? new Date(this.selectedDate) : today) < 0,
		);
	}

	generateRegularAppointmentsList() {
		this.regularAppointmentsList = this.appointments.filter(
			(data: any) =>
				differenceInDays(new Date(data.returnDate), this.selectedDate ? new Date(this.selectedDate) : today) >= 0,
		);
	}

	openClientDetails(event: any, cardData: any) {
		this.$router.push(`promotores/${cardData.id}`);
	}

	async fetchAppointments() {
		this.areAppointmentsLoading = true;
		this.currentPage = 1;
		const { data, total, page } = await SolutionAppointmentAppModel.getAppointmentList({
			limit: this.pageLimit,
			page: this.currentPage,
			isWithReturn: true,
			shouldGetOnlyWithSchedule: false,
			shouldGroupByClientName: true,
			shouldOrderByAppointmentReturnDate: true,
			appointmentStatus: [AppointmentStatus.CONFIRMED],
			userId: this.activeUser.id,
			...(this.selectedDate ? { date: this.selectedDate } : { date: this.today }),
		});
		this.appointments = data;
		this.totalAppointments = total;
		this.currentPage = page;

		this.generatePriorityAppointmentsList();
		this.generateRegularAppointmentsList();

		this.areAppointmentsLoading = false;
	}

	async loadAppointments() {
		//Prevenir várias requisições
		if (this.areAppointmentsLoading === true) {
			return;
		}
		if (this.totalAppointments > this.regularAppointmentsList.length + this.priorityAppointmentsList.length) {
			this.areAppointmentsLoading = true;
			const { data, total, page } = await SolutionAppointmentAppModel.getAppointmentList({
				limit: this.pageLimit,
				page: this.currentPage + 1,
				isWithReturn: true,
				shouldGetOnlyWithSchedule: false,
				shouldGroupByClientName: true,
				shouldOrderByAppointmentReturnDate: true,
				appointmentStatus: [AppointmentStatus.CONFIRMED],
				userId: this.activeUser.id,
				...(this.selectedDate ? { date: this.selectedDate } : { date: this.today }),
			});
			this.appointments.push(...data);
			this.totalAppointments = total;
			this.currentPage = page;

			this.generatePriorityAppointmentsList();
			this.generateRegularAppointmentsList();

			this.priorityAppointmentKey += 1;
			this.regularAppointmentKey += 1;
			this.areAppointmentsLoading = false;
		}
	}

	async created() {
		await this.fetchAppointments();
	}
}
