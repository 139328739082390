import InsurerPlanAppModel from "./insurer_plan_app.model";
import { CrudModel } from "./_crud";
import { crudGet } from "./_request";

export class corporateClientAppModel extends CrudModel {
	constructor() {
		super("app/corporateClient", [], [{ fieldKey: "insurerPlans", model: InsurerPlanAppModel }]);
	}

	async search({ page = 1, limit = 0, searchParam }: any) {
		return crudGet(this.endpoint, "", { page, limit, searchParam });
	}
}

export default new corporateClientAppModel();
