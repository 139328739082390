import corporateClientAppModel from "./corporate_client_app.model";
import servicePlanAppModel from "./service_plan_app.model";
import { CrudModel } from "./_crud";
import { crudGet } from "./_request";

export class InsurerPlanAppModel extends CrudModel {
	constructor() {
		super(
			"app/insurerPlan",
			[],
			[
				{ fieldKey: "corporateClient", model: corporateClientAppModel },
				{ fieldKey: "servicePlans", model: servicePlanAppModel },
			],
		);
	}

	async search({ page = 1, limit = 0, corporateClientIds }: any) {
		const params = { page, limit, corporateClientIds };

		return crudGet(this.endpoint, "", params);
	}
}

export default new InsurerPlanAppModel();
