import corporateClientAppModel from "./corporate_client_app.model";
import { CrudModel } from "./_crud";
import { crudRead } from "./_request";

export class clientAppModel extends CrudModel {
	constructor() {
		super("app/client", [], [{ fieldKey: "solutionCategory", model: corporateClientAppModel }]);
	}
	async getClientList(listDto: any) {
		return this.get("", listDto);
	}
	async read(id: string, queryParameters: any = {}) {
		return crudRead(this.endpoint, id, queryParameters);
	}
}

export default new clientAppModel();
